<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-lg-8>
    <v-flex xs12>
      <v-layout wrap justify-start py-4>
        <v-flex xs12>
          <v-card outlined class="app-border-color" rounded="lg">
            <v-layout wrap justify-start pa-4>
              <v-flex xs6 sm4 lg3>
                <span class="subtitle-2">
                  {{ $t("pages.wards.ward") }}
                </span>
                <br />
                <span class="title-3" v-if="service.ward">
                  {{ service.ward.name }}
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3>
                <span class="subtitle-2">
                  {{ $t("form.department") }}
                </span>
                <br />
                <span class="title-3">
                  <template v-for="(item, index) in service.departments">
                    <span :key="index">
                      {{ item.department.name }}
                    </span>
                    <span
                      v-if="index + 1 < service.departments.length"
                      :key="'dash-' + index"
                    >
                      |
                    </span>
                  </template>
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-sm-0>
                <span class="subtitle-2"> {{ $t("form.case_no") }} </span>
                <br />
                <span class="title-3">
                  {{ service.case_no ? service.case_no : "-" }}
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-lg-0>
                <span class="subtitle-2">
                  {{ $t("form.start_date") }}
                </span>
                <br />
                <span class="title-3">
                  {{ $moment(service.start_date).format("DD MMM YYYY") }}
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-lg-0>
                <span class="subtitle-2">
                  {{ $t("form.end_date") }}
                </span>
                <br />
                <span class="title-3">
                  {{ $moment(service.end_date).format("DD MMM YYYY") }}
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-lg-0>
                <span class="subtitle-2">
                  {{ $t("form.qualification") }}
                </span>
                <br />
                <span class="title-3">
                  <template v-for="(item, index) in service.qualifications">
                    <span :key="index">
                      {{ item.qualification.name }}
                    </span>
                    <span
                      v-if="index + 1 < service.qualifications.length"
                      :key="'dash-' + index"
                    >
                      |
                    </span>
                  </template>
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-lg-0>
                <span class="subtitle-2">
                  {{ $t("form.language") }}
                </span>
                <br />
                <span class="title-3">
                  <template v-for="(item, index) in service.languages">
                    <span :key="index">
                      {{ getLanguage(item.language.id) }}
                    </span>
                    <span
                      v-if="index + 1 < service.languages.length"
                      :key="'dash-' + index"
                    >
                      |
                    </span>
                  </template>
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-lg-0>
                <span class="subtitle-2">
                  {{ $t("form.gender") }}
                </span>
                <br />
                <span class="title-3">
                  <template v-for="(item, index) in service.genders">
                    <span :key="index">
                      {{ getGender(item.gender_id) }}
                    </span>
                    <span
                      v-if="index + 1 < service.genders.length"
                      :key="'dash-' + index"
                    >
                      |
                    </span>
                  </template>
                </span>
              </v-flex>
              <v-flex xs12 pt-4 pt-lg-0>
                <span class="subtitle-2">
                  {{ $t("form.lettering") }}
                </span>
                <br />
                <span class="title-3">
                  {{ service.lettering ? service.lettering : "-" }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout wrap justify-start pb-4>
        <v-spacer />
        <v-flex xs6 sm4 lg3 xl2 pt-2 pt-lg-0 pr-2 pr-lg-4>
          <v-card outlined rounded="lg" class="elevated-card">
            <div class="card-line warning"></div>
            <v-layout wrap justify-start pa-4>
              <v-flex xs12>
                <span class="title-1">{{ analytics.open }}</span> <br />
                <span class="subtitle-1 text-no-wrap">
                  {{ $t("status.open") }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <!--        <v-flex xs6 sm4 lg3 xl2 pt-2 pt-lg-0 pr-2 pr-sm-0 pr-lg-4>-->
        <!--          <v-card outlined rounded="lg" class="elevated-card">-->
        <!--            <div class="card-line green"></div>-->
        <!--            <v-layout wrap justify-start pa-4>-->
        <!--              <v-flex xs12>-->
        <!--                <span class="title-1">{{ analytics.assigned }}</span> <br />-->
        <!--                <span class="subtitle-1">-->
        <!--                  {{ $t("status.assigned") }}-->
        <!--                </span>-->
        <!--              </v-flex>-->
        <!--            </v-layout>-->
        <!--          </v-card>-->
        <!--        </v-flex>-->
        <!--        <v-flex xs6 sm4 lg3 xl2 pt-2 pt-lg-0 pr-2 pr-sm-0 pr-lg-4>-->
        <!--          <v-card outlined rounded="lg" class="elevated-card">-->
        <!--            <div class="card-line blue"></div>-->
        <!--            <v-layout wrap justify-start pa-4>-->
        <!--              <v-flex xs12>-->
        <!--                <span class="title-1">{{ analytics.archived }}</span> <br />-->
        <!--                <span class="subtitle-1">-->
        <!--                  {{ $t("status.archived") }}-->
        <!--                </span>-->
        <!--              </v-flex>-->
        <!--            </v-layout>-->
        <!--          </v-card>-->
        <!--        </v-flex>-->
        <!--        <v-flex xs6 sm4 lg3 xl2 pt-2 pt-lg-0 pr-2 pr-sm-0>-->
        <!--          <v-card outlined rounded="lg" class="elevated-card">-->
        <!--            <div class="card-line red"></div>-->
        <!--            <v-layout wrap justify-start pa-4>-->
        <!--              <v-flex xs12>-->
        <!--                <span class="title-1">{{ analytics.inactive }}</span> <br />-->
        <!--                <span class="subtitle-1">-->
        <!--                  {{ $t("status.deactivated") }}-->
        <!--                </span>-->
        <!--              </v-flex>-->
        <!--            </v-layout>-->
        <!--          </v-card>-->
        <!--        </v-flex>-->
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-layout wrap justify-start v-if="list.length">
        <v-flex xs12 pb-4 class="title-2">
          {{ $t("pages.settings.shift") }}
        </v-flex>
        <v-flex xs12>
          <AppSimpleTable
            :headings="headings"
            :items="list"
            :pagination="pagination"
            empty-title="pages.services.no_service_to_show"
            @paginate="(val) => (pagination.page = val)"
            @delete="toggleDelete"
          >
            <template v-slot:action="{ data }">
              <v-layout wrap justify-start py-2>
                <v-flex text-center>
                  <img
                    v-if="
                      data.item.status.key !== 4 && data.item.status.key !== 3
                    "
                    src="@/assets/icons/trash.svg"
                    height="20px"
                    alt="delete"
                    class="cursor-pointer"
                    @click="toggleStatus(data.item)"
                  />
                  <!--                  <v-icon-->
                  <!--                    v-if="data.item.status.key === 4"-->
                  <!--                    class="cursor-pointer"-->
                  <!--                    @click="toggleStatus(data.item)"-->
                  <!--                  >-->
                  <!--                    mdi-reload-->
                  <!--                  </v-icon>-->
                  <!--                  <img-->
                  <!--                    v-else-->
                  <!--                    src="@/assets/icons/trash.svg"-->
                  <!--                    height="20px"-->
                  <!--                    alt="delete"-->
                  <!--                    class="cursor-pointer"-->
                  <!--                    @click="toggleStatus(data.item)"-->
                  <!--                  />-->
                </v-flex>
              </v-layout>
            </template>
          </AppSimpleTable>
        </v-flex>
      </v-layout>
    </v-flex>
    <DeleteConfirmation
      v-model="showDelete"
      :item="selectedItem"
      title="pages.services.delete_shift"
      @cancel="toggleDelete"
      @submit="confirmChangeShiftStatus"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { _GENDER } from "@/constants/genders";
import { _SHIFTS, SHIFT_STATUS } from "@/constants/shifts";
import Helpers from "@/libs/helper/components/helper-functions";
import { LANGUAGES } from "@/constants/languages";

export default {
  name: "Service",
  data: () => ({
    selectedItem: {},
    showDelete: false,
    headings: [
      { name: "", value: "index", style: "width:80px" },
      { name: "form.start_date", value: "start_date" },
      { name: "form.end_date", value: "end_date" },
      { name: "pages.settings.shift", value: "shift" },
      { name: "form.assigned_to", value: "assigned_to" },
      { name: "table.status", value: "status", type: "status" },
      // { name: "form.lettering", value: "lettering" },
      {
        name: "table.actions",
        value: "action",
        style: "max-width:200px",
        options: [],
      },
    ],
    filter: {
      sort_by: ["date", "start_time"],
      sort_by_dir: ["desc", "desc"],
    },
    pagination: {
      per_page: 25,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
    analytics: {
      open: 0,
      assigned: 0,
      archived: 0,
      inactive: 0,
    },
  }),
  watch: {
    "pagination.page"() {
      this.updateQueryParams();
      this.getServiceShiftsList();
    },
  },
  computed: {
    ...mapGetters({
      getService: "hospital/getService",
      getShifts: "hospital/getServiceShifts",
    }),
    service() {
      const breadCrumbs = [
        {
          name: "services",
          text: "pages.services.title",
        },
        {
          name: this.$route.name,
          params: this.$route.params,
          text: this.getService.name ?? this.getService.id,
        },
      ];
      this.$store.commit("app/setBreadCrumbs", breadCrumbs);
      return this.getService;
    },
    getAddress() {
      return this.service?.departments[0]?.department?.address;
    },
    list() {
      if (this.getShifts)
        return this.getShifts?.map((itm, index) => {
          const start_date_time = itm.date + " " + itm.start_time;
          const end_date_time = itm.date + " " + itm.end_time;
          const start_date = this.$moment(
            start_date_time,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD MMM YYYY HH:mm");
          let end_date = this.$moment(
            end_date_time,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD MMM YYYY HH:mm");
          if (end_date < start_date)
            end_date = this.$moment(end_date, "DD MMM YYYY HH:mm")
              .add(1, "days")
              .format("DD MMM YYYY HH:mm");

          return {
            index: this.pagination.from + index,
            id: itm.id,
            start_date: start_date,
            end_date: end_date,
            assigned_to: itm.assigned_to
              ? itm.assigned_to?.first_name + " " + itm.assigned_to?.last_name
              : "",
            lettering: itm.assigned_to?.lettering ?? "",
            shift: this.getShift(itm.shift_id),
            status: this.getShiftStatus(itm.status),
            rowStyle: {
              // backgroundColor: itm.assigned_to ? "" : "#ff520045",
            },
          };
        });
      return [];
    },
  },
  created() {
    this.pagination.page = parseInt(this.$route.query.page) || 1;
    this.getServiceList(this.$route.params.id);
    this.getServiceShiftsList();
  },
  methods: {
    ...mapActions({
      getServiceList: "hospital/getService",
      getServiceShifts: "hospital/getServiceShifts",
      deleteShift: "hospital/deleteShift",
      activateShift: "hospital/activateShift",
      deactivateShift: "hospital/deactivateShift",
    }),

    updateQueryParams() {
      this.$router.replace({
        query: {
          page: this.pagination.page,
        },
      });
    },
    getServiceShiftsList() {
      const payload = {
        ...this.filter,
        ...this.pagination,
        id: this.$route.params.id,
      };
      this.getServiceShifts(payload).then((res) => {
        this.analytics = res.count;
        this.pagination = Helpers.setPagination(res);
      });
    },
    getLanguage(id) {
      return this.$t(
        LANGUAGES.find((itm) => {
          return itm.value === id;
        })?.label
      );
    },
    getGender(gender) {
      return _GENDER[gender];
    },
    getShift(shift) {
      return _SHIFTS[shift];
    },
    getShiftStatus(shift) {
      return SHIFT_STATUS.find((item) => item.key === shift);
    },
    toggleDelete(val) {
      if (val)
        this.selectedItem = { ...val, name: this.$t("pages.settings.shift") };
      else this.selectedItem = {};
      this.showDelete = !this.showDelete;
    },
    toggleStatus(val) {
      if (val)
        this.selectedItem = { ...val, name: this.$t("pages.settings.shift") };
      else this.selectedItem = {};
      this.showDelete = !this.showDelete;
    },
    confirmDeleteShift() {
      this.deleteShift(this.selectedItem.id).then(() => {
        this.toggleDelete();
        this.getServiceShiftsList();
      });
    },
    confirmChangeShiftStatus() {
      if (this.selectedItem.status.key === 4)
        this.activateShift(this.selectedItem.id).then(() => {
          this.toggleDelete();
          this.getServiceShiftsList();
        });
      else
        this.deactivateShift(this.selectedItem.id).then(() => {
          this.toggleDelete();
          this.getServiceShiftsList();
        });
    },
  },
};
</script>

<style scoped></style>
